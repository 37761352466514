import logo from './logo.svg';
import './App.css';
import { OffCanvasNav } from './OffCanvasNav.js'

function App() {
  return (
    <>
      <div className="OffCanvasNav">
        <OffCanvasNav/>
      </div>
      <div className="App">
        <header className="App-header">
        This will be the main page
          {/* <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a> */}
        </header>
      </div> 
    </>
  );
}

export default App;
