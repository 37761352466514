import React, { useState } from "react";
import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'
import './App.css';
import { Link } from "react-router-dom";

function OffCanvasNav() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Launch
            </Button>

            <Offcanvas show={show} onHide={handleClose} placement={'start'}>
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <nav>
                    <Link to="/">Home</Link> |{" "}
                    <Link to="rsvp">RSVP</Link>
                </nav>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
  
export { OffCanvasNav }