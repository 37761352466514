import React from "react";
import { OffCanvasNav } from './OffCanvasNav.js'

function RSVP() {
    return (
        <>
            <div className="OffCanvasNav">
                <OffCanvasNav/>
            </div>
            <header className="App-header">
                This will be the RSVP page
            </header>
        </>
    )
}

export { RSVP }